import React, { useState } from 'react'
import PropTypes from 'prop-types'
import tw from 'twin.macro'
import styled from 'styled-components'
import Img from 'gatsby-image/withIEPolyfill'

import Icon from '@objects/icon'

const AssetInfoWrapper = styled.div.attrs({
  className: 'flex flex-col md:flex-row justify-between mt-3 md:mt-2',
})``
const AssetTitle = styled.div.attrs({
  className: 'font-normal leading-5 mb-2',
})``

const StyledVideoAssetWrapper = styled.div.attrs({
  className: 'w-full relative',
})`
  height: 0;
  padding-top: calc(9 / 16 * 100%);
  overflow: hidden;

  video,
  img {
    ${tw`
      m-auto
      absolute
      top-0
      bottom-0
      left-0
      right-0
    `}
  }
`

const StyledPlayIcon = styled(Icon).attrs({
  name: 'Play',
})`
  transform: translate(-50%, -50%);
  height: 54px !important;
  width: 54px !important;

  ${tw`
    m-auto
    absolute
    top-1/2
    left-1/2
  `}
`

function EmbeddedVideo({ data }) {
  const [showVideo, setShowVideo] = useState(false)

  const {
    title,
    video: { src: videoSrc },
    placeholder,
  } = data

  const video = (
    <>
      {(showVideo && (
        <StyledVideoAssetWrapper className={'video-asset-wrapper'}>
          <video controls={true} autoPlay>
            <source src={videoSrc} />
          </video>
        </StyledVideoAssetWrapper>
      )) || (
        <StyledVideoAssetWrapper
          className={'video-asset-wrapper'}
          onClick={() => {
            setShowVideo(true)
          }}
        >
          <Img
            fluid={placeholder.image}
            alt={placeholder.description}
            background={true}
          />
          <StyledPlayIcon />
        </StyledVideoAssetWrapper>
      )}
      <AssetInfoWrapper>
        {title && <AssetTitle>{title}</AssetTitle>}
      </AssetInfoWrapper>
    </>
  )

  return <div data-testid={'embedded-video'}>{video}</div>
}

EmbeddedVideo.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    video: PropTypes.shape({
      src: PropTypes.string,
    }),
    placeholder: PropTypes.shape({
      image: PropTypes.object,
      description: PropTypes.string,
    }),
  }),
}

export default EmbeddedVideo
